import type { SlotId } from './config';
import type { ReelSetType } from './gql/query';
import type Animation from './slotMachine/animations/animation';
import type Tween from './slotMachine/animations/tween';
import type { BgSkin } from './slotMachine/background/background';
import type { SlotMachineState } from './slotMachine/config';
import { WinStages } from './slotMachine/config';
import type {
  /*IWinLine,*/
  Icon,
  LineSet,
} from './slotMachine/d';
import { TesterAPI } from './slotMachine/devTool/testerApi';

export interface IBalance {
  amount: number;
  currency: string;
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      isBonus: boolean;
      bonuses: UserBonus[];
      features: {
        isFreeSpins: boolean;
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
  rewards: BetReward[];
}

export type BetRewardType = 'BetBonusReward' | 'BetCoinReward' | 'ReplayBonusReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};
export type BetReward = BetBonusReward | BetCoinReward;

export type BonusType = 'FREE_SPIN' | 'SPECIAL_ROUND' | 'PRE_LOADED' | 'GAME_MODE' | '';
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};

export type UserBonusData = {
  count: number;
  creditMultiplier: number;
  debitMultiplier: number;
  initialRoundId: string;
  maxRound: number;
  rounds?: number;
  frbReferenceId: string | null;
};

export type UserBonus = {
  bonusId: string;
  id: string;
  gameMode: GameMode;
  isActive: boolean;
  currentRound: number;
  totalRounds: number;
  betId: string;
  rounds: number;
  reelSetId: string;
  bonus: Bonus;
  totalWinAmount: number;
  coinAmount: number;
  coinValue: number;
  data: UserBonusData;
  status: BonusStatus;
  isFreeBet?: boolean;
  isActive: boolean;
  currentRound: number;
};

export type FreeRoundBonus = {
  id: string;
  bonusId: string;
  status: BonusStatus;
  coinAmount: number;
  coinValue: number;
  rounds: number;
  //roundsPlayed: number;
  totalWinAmount: number;
  isActive: boolean;
  currentRound: number;
};

export type Bonus = {
  id: string;
  slotId: string;
  reelSetId: string;
  type: BonusType;
  title?: string;
  description?: string;
  lineSetId: string | null;
  coinAmount: number;
  purchasable: boolean;
  rounds: number;
  data: unknown;
};

export enum FeatureState {
  NORMAL,
  MAX,
}

export enum GameMode {
  REGULAR,
  FREE_SPINS_LVL1,
  FREE_SPINS_LVL2,
  FREE_SPINS_LVL3,
  FREE_SPINS_LVL4,
  FREE_SPINS_LVL5,
  BUY_FEATURE,
  BUY_FEATURE_MAX,
}

export enum ReelId {
  REGULAR = '2338efb6-c2b4-48b7-91d3-ab8744609f37',
  FREE_SPINS_LVL1 = '42d1b1cb-fc66-43b7-b34f-2de194cb44e4',
  FREE_SPINS_LVL2 = '46e2bfd4-07d7-4ffb-a2cc-78f560807ae9',
  FREE_SPINS_LVL3 = '04c97498-8c96-426b-ae79-9065c9e2f047',
  FREE_SPINS_LVL4 = 'ef1ea8fb-d8e2-4aec-aead-44822194d9f5',
  FREE_SPINS_LVL5 = 'f5e64636-b82a-4f1f-8728-40bd53169a3d',
  BUY_FEATURE = 'c9f988c2-514a-47d0-9aa3-ccac069a48f2',
  BUY_FEATURE_MAX = 'b0180d54-6c9c-41d3-81d0-3332ca14a717',
}

export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: ReelId.REGULAR,
  [GameMode.FREE_SPINS_LVL1]: ReelId.FREE_SPINS_LVL1,
  [GameMode.FREE_SPINS_LVL2]: ReelId.FREE_SPINS_LVL2,
  [GameMode.FREE_SPINS_LVL3]: ReelId.FREE_SPINS_LVL3,
  [GameMode.FREE_SPINS_LVL4]: ReelId.FREE_SPINS_LVL4,
  [GameMode.FREE_SPINS_LVL5]: ReelId.FREE_SPINS_LVL5,
  [GameMode.BUY_FEATURE]: ReelId.BUY_FEATURE,
  [GameMode.BUY_FEATURE_MAX]: ReelId.BUY_FEATURE_MAX,
};

export const bonusIds: Record<GameMode, string> = {
  [GameMode.FREE_SPINS_LVL1]: '1f2b6224-60a2-4177-b1da-7539ba8f389f',
  [GameMode.FREE_SPINS_LVL2]: 'f0e22970-4c05-41a6-b6c3-1e83aeb6bb4c',
  [GameMode.FREE_SPINS_LVL3]: 'da129fa0-931d-44e1-9705-303412bfeef9',
  [GameMode.FREE_SPINS_LVL4]: 'bed2bc65-7d11-44f6-8221-525239f5dd5c',
  [GameMode.FREE_SPINS_LVL5]: 'fa024b43-7776-407c-bf5a-6a07dabff7ab',
  [GameMode.BUY_FEATURE]: '8dee6815-e11a-4a36-8bcc-d7b9ae9b0744',
  [GameMode.BUY_FEATURE_MAX]: '53a48d39-e10b-4a96-9a9c-5a62de4b9dd5',
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
  reelSetId: string;
}
export interface GetUserBonusesInput {
  id: string;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: number;
  currentSpin: number;
};

export type MessageBannerProps = {
  title: string;
  subTitle: string;
  btnText: string;
  freeSpinsSubtitle: string;
  winTitle: string;
  winTotalSpins: string;
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
  isLevelMax?: boolean;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
};

declare global {
  interface Window {
    __PIXI_APP__: PIXI.Application;
    __: TesterAPI;
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      APP_NAME: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    PIXI: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
};

export enum TotalWinBannerMode {
  NON,
  DISABLE,
  ENABLE,
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  FREE_SPIN = 'FREE_SPIN',
}

export enum BgmSoundTypes {
  BASE = 'regular',
  FS_LEVEL_1 = 'fsLevel1',
  FS_LEVEL_2 = 'fsLevel2',
  FS_LEVEL_3 = 'fsLevel3',
  FS_LEVEL_4 = 'fsLevel4',
  FS_LEVEL_5 = 'fsLevel5',
}

export enum ReelAnticipation {
  NON = 'NON',
  BONUS = 'BONUS',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  // HIDE_WIN_LINES = 'hideWinLines',
  HIDE_ALL_WIN_LINES = 'hideAllWinLines',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_UI_BUTTON = 'resizeUiButton',
  RESIZE_BACK_GROUND = 'ResizeBackGround',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  RESIZE_GAME_CONTAINER_FREE_SPIN = 'resizeGameContainerFreeSpin',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_SLOTS_STOP_DISPLAY_VISIBILITY = 'setSlotsStopDisplayVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  // SHOW_WIN_LINES = 'showWinLines',
  // TODO SHOW_WIN_LABEL = 'showWinLabel',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  // SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SETUP_BET_RESULT = 'setupBetResult',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  REMOVE_ANTICIPATION_TINT = 'removeAnticipationTint', // TODO
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREE_SPINS_WIN = 'isFreeSpinWin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_TOTAL_WIN_MESSAGE_BANNER = 'isTotalWinMessageBanner',
  SET_IS_COUNT_UP = 'setIsCountUp',
  COUNT_UP_START = 'countUpStart',
  SET_IS_IN_TRANSITION = 'isInTransition',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_UPDATE_FREE_SPINS_COUNT = 'handleUpdateFreeSpinsCount',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  SET_STATE = 'setState',
  SLOT_STOP_DISPLAY_HIDE = 'SlotStopDisplayHide',
  SET_ENDING_DISPLAY = 'SetEndingDisplay',
  SET_SLOT_BUSY_DISABLE = 'SetSlotBusyDisable',
  PHOENIX_START = 'PhoenixStart',
  UI_VISIBLE = 'UiVisible',
  SET_BACKGROUND_PIVOT_Y = 'SetBackGroundPivotY',
  CREATE_END_MESSAGE = 'CreateEndMessage',
  SPIN_END = 'SpinEnd',
  BONUS_END = 'BonusEnd',
  BONUS_WIN = 'BonusWin',
  TOGGLE_UI_MENU = 'ToggleUiMenu',
  WINDOW_NOT_ACTIVE = 'WindowNotActive',
  FREE_SPIN = 'FreeSpin',
  PLACE_BET_COMPLETED = 'placeBetCompleted',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_IS_ACTIVE_FREE_SPINS_GAME = 'handleIsActiveFreeSpinsGame',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SCENE_CHANGE_START = 'SceneChangeStart',
  START_GET_AMOUNT_WIN = 'StartGetAmountWin',

  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_RETRIGGER_MESSAGE_BANNER = 'createRetriggerMessageBanner',
  CHANGE_DEVICE_ORIENTATION = 'changeDeviceOrientation',
  END_RETRIGGER_FEATURE = 'endRetriggerFeature',
  SHOW_MAX_LEVEL_LABEL = 'showMaxLevelLabel',
  SET_NEXT_FREE_SPINS_LEVEL = 'setNextFreeSpinsLevel',
  JINGLE_START = 'jingleStart',
  START_RETRIGGER_ANIMATION = 'startRetrigger',
  END_RETRIGGER_ANIMATION = 'endRetrigger',
  SHOW_SAFE_AREA = 'showSafeArea',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',

  OPEN_POPUP = 'openPopup',
  CLOSE_POPUP = 'closePopup',
  SET_IS_FADEOUT = 'setIsFadeOut',

  SET_BROKEN_BUY_FEATURE = 'setBrokenFeature',

  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  // frb
  UPDATE_FREE_SPIN_ROUND = 'updateFreeSpinRound',
  SET_IS_FREE_ROUND_BONUS = 'setIsFreeRoundBonus',
  SET_IS_POPUP_FREE_ROUNDS_OPENED = 'isOpenPopupFreeRounds',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE = 'updateFreeRoundBonusTotalWinValue',
  OPEN_POPUP_FREE_ROUNDS = 'openPopupFreeRounds',
  OPEN_POPUP_FREE_ROUNDS_END = 'openPopupFreeRoundsEnd',
  START_FADE_TRANSITION_FREE_ROUNDS_BONUS = 'startFadeTransitionFreeRoundBonus',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoPlay',
  SET_RESULT = 'setResult',
  INVALID_BONUS_CHECK = 'invalidBoundCheck',
}

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};

export interface Events {
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: () => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: (reel?: number) => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: (isExpand: boolean, value?: number) => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (
    props: MessageBannerProps & {
      totalWin: string;
      totalSpins: number;
      level: number;
    },
  ) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  // [EventTypes.HIDE_WIN_LINES]: (line: IWinLine[]) => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_UI_BUTTON]: (width: number, height: number) => void;
  [EventTypes.RESIZE_BACK_GROUND]: (scale: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.RESIZE_GAME_CONTAINER_FREE_SPIN]: (isLandscape: boolean) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_SLOTS_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (
    nextResult: ISettledBet,
    isTurboSpin: boolean,
    isScatter: boolean,
    isExpand: boolean,
  ) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
    background?: BgSkin;
  }) => void;
  //[EventTypes.SHOW_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.HIDE_ALL_WIN_LINES]: () => void;
  // TODO [EventTypes.SHOW_WIN_LABEL]: () => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: Icon[], reelId?: number) => void;
  // [EventTypes.SHOW_TINT]: (state: boolean, reelId?: number) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: number[],
    scatterNo: number[],
    anticipationStartReelId: number,
    anticipationEndReelId: number,
  ) => void;
  [EventTypes.SETUP_BET_RESULT]: (nextResult: ISettledBet) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.REMOVE_ANTICIPATION_TINT]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREE_SPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenedMessageBanner: boolean) => void;
  [EventTypes.SET_IS_TOTAL_WIN_MESSAGE_BANNER]: (isOpenedMessageBanner: setIsTotalWinMessageBanner) => void;
  [EventTypes.SET_IS_COUNT_UP]: (isCountUp: boolean) => void;
  [EventTypes.COUNT_UP_START]: (mode: WinStages) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT]: (spins: number, curr: number, immediately: boolean) => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: () => void;
  [EventTypes.SET_STATE]: (state: SlotMachineState) => void;
  [EventTypes.SLOT_STOP_DISPLAY_HIDE]: (slots: number) => void;
  [EventTypes.SET_ENDING_DISPLAY]: (reelPositions: number[], reelSetId: string) => void;
  [EventTypes.SET_SLOT_BUSY_DISABLE]: () => void;
  [EventTypes.PHOENIX_START]: () => void;
  [EventTypes.UI_VISIBLE]: () => void;
  [EventTypes.SET_BACKGROUND_PIVOT_Y]: (pivotY: number) => void;
  [EventTypes.CREATE_END_MESSAGE]: () => void;
  [EventTypes.SPIN_END]: () => void;
  [EventTypes.BONUS_END]: () => void;
  [EventTypes.BONUS_WIN]: () => void;
  [EventTypes.TOGGLE_UI_MENU]: () => void;
  [EventTypes.WINDOW_NOT_ACTIVE]: () => void;
  [EventTypes.FREE_SPIN]: (isTurboSpin: boolean | undefined) => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.HANDLE_BUY_BONUS]: (bonusId: string) => void;
  [EventTypes.START_BUY_FEATURE_ROUND]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: (isBuyFeaturePopup: boolean) => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (total: number, current: number, immediately?: boolean) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP]: () => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME]: (isVisible: boolean) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.SCENE_CHANGE_START]: (isFreeSpins: boolean, reelPositions?: number[], reelSetId?: string) => void;
  [EventTypes.START_GET_AMOUNT_WIN]: () => void;
  [EventTypes.CREATE_FREE_SPINS_TITLE]: (props: FreeSpinsTitleProps) => void;
  [EventTypes.REMOVE_FREE_SPINS_TITLE]: () => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageBannerProps) => void;
  [EventTypes.CREATE_RETRIGGER_MESSAGE_BANNER]: (
    props: MessageBannerProps & { level: number; lastLevelRetriger: boolean },
  ) => void;
  [EventTypes.CHANGE_DEVICE_ORIENTATION]: (isPortrait: boolean, width: number, height: number) => void;
  [EventTypes.END_RETRIGGER_FEATURE]: () => void;
  [EventTypes.SHOW_MAX_LEVEL_LABEL]: () => void;
  [EventTypes.SET_NEXT_FREE_SPINS_LEVEL]: (settings: { mode: GameMode }) => void;
  [EventTypes.JINGLE_START]: () => void;
  [EventTypes.START_RETRIGGER_ANIMATION]: () => void;
  [EventTypes.END_RETRIGGER_ANIMATION]: () => void;
  [EventTypes.SHOW_SAFE_AREA]: () => void;

  [EventTypes.OPEN_POPUP]: (popupType: PopupTypes) => void;
  [EventTypes.CLOSE_POPUP]: () => void;
  [EventTypes.SET_IS_FADEOUT]: (boolean) => void;
  [EventTypes.SET_BROKEN_BUY_FEATURE]: (boolean) => void;

  [EventTypes.FORCE_CLOSE_BUYFEATURE]: () => void;
  [EventTypes.SET_REPLAY_BET_ID]: () => void;
  [EventTypes.UPDATE_FREE_SPIN_ROUND]: () => void;
  [EventTypes.SET_IS_FREE_ROUND_BONUS]: (isFreeRoundBonus: boolean) => void;
  [EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED]: (isPopupFreeRoundsOpened: boolean) => void;
  [EventTypes.UPDATE_FREE_ROUNDS_LEFT]: (rounds: number) => void;
  [EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS]: (rounds: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS_END]: (isExpired?: boolean) => void;
  [EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS]: (settings: {
    outDuration: number;
    inDuration: number;
    callback: () => void;
  }) => void;
  [EventTypes.FORCE_STOP_AUTOPLAY]: () => void;
  [EventTypes.INVALID_BONUS_CHECK]: () => void;
  [EventTypes.START_FREE_ROUND_POPUP]: () => void;
}

export enum PopupTypes {
  BUY_FEATURE,
  BUY_FEATURE_CONFIRMATION,
  FREE_SPINS,
  FREE_SPINS_END,
  RAGE_MODE,
  RAGE_MODE_INFO,
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY_POPUP = 'AUTOPLAY_POPUP',
  BET_SETTINGS_POPUP = 'BET_SETTINGS_POPUP',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
  INFO_POPUP = 'INFO_POPUP',
  HISTORY_POPUP = 'HISTORY_POPUP',
  TURBO_SPIN = 'TURBO_SPIN',
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

// TODO 変更すること！
export const freeRoundBonusId = '2284de4d-edd6-4eaf-8e04-5f6a99fa8d05';
